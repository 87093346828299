@use '../../../../resources/css/functions' as *;


.db-coverage-chart {
  .icon-square_solid {
    margin: 0 toRem(8px) 0 0;
  }

  &__percent {
    min-width: toRem(130px);
    max-width: max-content;
    height: toRem(17px);
  }

  &__chart {
    .p-chart {
      min-width: toRem(69px);
      min-height: toRem(69px);
      max-width: toRem(98px);
      max-height: toRem(98px);
    }
    margin: toRem(22px) 0;
  }

  &__coverage {
    min-width: toRem(193px);
    max-width: max-content;
    height: toRem(17px);
    margin: 0 0 toRem(8px) 0;
  }

  &__unaccounted {
    min-width: toRem(228px);
    max-width: max-content;
    height: toRem(18px);
  }
}

.db-coverage-chart-assets {
  &__chart {
    width: toRem(85px);
    height: toRem(85px);

    .p-chart {
      width: toRem(85px);
      height: toRem(85px);
    
    }
  }
  &__chart-container {
    display: flex;
    align-items: center;
    margin-top: toRem(12px);
    gap: toRem(15px);
  }
  &__values-container {
    display: flex;
    flex-direction: column;
    gap: toRem(10px);
  }
  &__coverage,
  &__unaccounted {
    display: flex;
    align-items: center;
    gap: toRem(9px);
  }
}