@mixin loadingSpinner($colour: $white, $size: 1.375rem) {
    @keyframes spin {
        to {
            -webkit-transform: rotateZ(360deg);
            transform-origin: center;
        }
    }

    &::before { 
        content: '';
        display: block;
        position: relative;
        width: $size;
        height: $size;
        border: 2px solid rgba(red($colour), green($colour), blue($colour), 0.3);
        border-top-color: #{$colour};
        border-radius: 50%;
        margin-left: 0;
        margin-right: 0;
        animation: spin 1s ease infinite;
    }
}