//Neutrals
$black: #000000;
$white: #ffffff;
$grey: #f0f0f0;

//Evora colours
$deep_ocean: #181331;
$foliage: #74b48b;
$sand: #dad2c1;
$nature: #ce6d2b;
$forest: #255056; // Default text color

//SIERA colours
$pink: #e63ed7;
$green: #009c8d;
$red: #e44829;
$blue: #204be1;
$yellow: #d1c022;
$pink_dark: #B51963;
$green_dark: #89CE00;
$brown: #855900;
$blue_dark: #502071;
$grey_light: #516156;
$green_light: #8AA0A3;

//Opacities
$forest_90: rgba($forest, 0.9);
$forest_80: rgba($forest, 0.8);
$forest_70: rgba($forest, 0.7);
$forest_50: rgba($forest, 0.5);
$forest_40: rgba($forest, 0.4);
$forest_20: rgba($forest, 0.2); // For seperators or table borders
$forest_15: rgba($forest, 0.15);
$forest_10: rgba($forest, 0.1);
$forest_05: rgba($forest, 0.05);
$forest_08: rgba($forest, 0.08);
$red_80: rgba($red, 0.8);
$red_50: rgba($red, 0.5);
$red_15: rgba($red, 0.15);
$red_10: rgba($red, 0.1);
$red_20: rgba($red, 0.20);
$green_50: rgba($green, 0.5);
$green_15: rgba($green, 0.15);
$green_20: rgba($green, 0.20);
$yellow_50: rgba($yellow, 0.5);
$foliage_50: rgba($foliage, 0.5);
$nature_20: rgba($nature, 0.20);

//EPC colours
$EPC_A: #357221;
$EPC_B: #5d8e4d;
$EPC_C: #86aa7a;
$EPC_D: #f1d24d;
$EPC_E: #ff8c00;
$EPC_F: #e86247;
$EPC_G: #b92c10;

//Border radius
$sborder-radius-s: 0.125rem;
$sborder-radius-m: 0.188rem;
$sborder-radius: 0.313rem;
$sborder-radius-xl: 0.438rem;
$sborder-radius-6: 0.375rem;
$sborder-radius-8: 0.5rem;
$border-radius-3: 3px;
$border-radius-4: 4px;
$border-radius-5: 5px;
$border-radius-17: 17px;
$borderWidth: 3; //Not given any px or rem because this width using for chart JS
$border-radius-19-5: 19.5px;

$header-height: 4.375rem;
$header-height-56: 3.5rem;
$height_fit_content: "fit-content";
$px_1080: 67.5rem;

$font-family-base: "Atkinson Hyperlegible", "Arial", "Segoe UI", "sans-serif";

$input-field-height: 3em;
$icons-root-path: "../../../assets/images/icon";
$max-width-1480: calc(1480px + 60px); //take into account the gutter
$min-width-1280: 1280px;

//Font size
$font_size_12: 0.75rem;
$font_size_14: 0.875rem;
$font-size_20: 1.25rem;

//Font weight
$font_weight_600: 600;

.xxx-small-text {
  font-family: $font-family-base;
  font-weight: 300;
  font-size: 55%; //xx-small
}

.xxx-small-text-bold {
  @extend .xxx-small-text;
  font-weight: 500; //xx-small
}

.xx-small-text {
  font-family: $font-family-base;
  font-weight: 100;
  font-size: 65%; //xx-small
}

.xx-small-text-1 {
  font-weight: normal;
  font-size: 62.5%;
}

.xx-small-bold-text {
  font-size: 62.5%;
  font-weight: 1000;
}

.xx-small-text-2 {
  font-family: $font-family-base;
  font-style: normal;
  font-weight: 400;
  font-size: 62.5%;
}

.extra-small-text {
  @extend .normal-font-family-style;
  font-weight: normal;
  font-size: 68.8%;
}

.xx-small-text-bold {
  @extend .xx-small-text;
  font-weight: 400;
}

.x-small-text-light {
  font-family: $font-family-base;
  font-weight: 100;
  font-size: 70%; //x-small
}

.x-small-text {
  font-family: $font-family-base;
  font-weight: 400;
  font-size: 70%; //x-small
}

.x-small-text-bold {
  @extend .x-small-text;
  font-weight: 600;
}

.x-small-text-black {
  @extend .x-small-text;
  line-height: 1.3rem;
  color: $forest;
  font-size: 74% !important;
  font-weight: 500;
}

.smaller-text-light {
  font-family: $font-family-base;
  font-weight: 100;
  font-size: 75%; //smaller
}

.smaller-text {
  font-family: $font-family-base;
  font-weight: 400;
  font-size: 75%; //smaller
}

.smaller-text-2 {
  font-family: $font-family-base;
  font-style: normal;
  font-weight: 400;
  font-size: 75%; //smaller
}

.smaller-text-normal {
  @extend .normal-font-family-style;
  font-weight: normal;
  font-size: 75%;
}

.xx-smaller-text-normal {
  @extend .normal-font-family-style;
  font-size: 50%;
  font-weight: 400;
}

.smaller-text-bold {
  @extend .smaller-text;
  font-weight: 500;
}

.small-text {
  font-family: $font-family-base;
  font-weight: 400;
  font-size: 80%; //small
}

.small-text-bold {
  @extend .small-text;
  font-weight: 700;
}

.small-icon-bold {
  font-size: 70%;
  font-weight: 600;
}

.medium-text {
  font-family: $font-family-base;
  font-weight: 400;
  font-size: 87.5%; //medium
  line-height: 20px;
}

.medium-text-2 {
  @extend .normal-font-family-style;
  font-weight: normal;
  font-size: 87.5%;
}

.medium-text-3 {
  font-family: $font-family-base;
  font-style: normal;
  font-weight: 400;
  font-size: 87.5%;
}

.large-text-1 {
  font-family: $font-family-base;
  font-style: normal;
  font-weight: 500;
  font-size: 87.5%;
}

.large-text-2{
  font-family: $font-family-base;
  font-style: normal;
  font-weight: 700;
  font-size: 87.5%;
}

.medium-text-bold {
  @extend .medium-text;
  font-weight: 600;
}

.ex-medium-text {
  font-family: $font-family-base;
  font-weight: 500;
  font-size: 94%; //15px
}

.large-text-light {
  font-family: $font-family-base;
  font-weight: 300;
  font-size: 95%; //large
}

.large-text {
  @extend .larger-text-light;
  font-weight: 500 !important; //large
}

.large-text-bold {
  @extend .larger-text-light;
  font-weight: 700 !important; //large
}

.large-text-bold-1 {
  font-weight: 500;
  font-size: 93.8%;
}

.large-text-bold-2 {
  @extend .normal-font-family-style;
  font-weight: 500;
  font-size: 93.8%;
}

.ex-large-text-light {
  font-family: $font-family-base;
  font-weight: 400;
  font-size: 100%; //ex-large
}

.ex-large-text-light-2 {
  font-family: $font-family-base;
  font-style: normal;
  font-weight: 400;
  font-size: 100%;
}

.ex-large-text {
  font-family: $font-family-base;
  font-style: normal;
  font-weight: 500;
  font-size: 100%; //ex-large
}

.ex-large-text-bold {
  @extend .ex-large-text;
  font-weight: 700; //ex-large
}

.larger-text-light {
  font-family: $font-family-base;
  font-weight: 300;
  font-size: 110%; //larger
}

.larger-text-light-1 {
  font-family: $font-family-base;
  font-weight: 400;
  font-size: 115%; //larger
}

.larger-text {
  font-family: $font-family-base;
  font-weight: 700;
  font-size: 110%; //larger
}

.larger-text-1 {
  font-family: $font-family-base;
  font-weight: 700;
  font-size: 120%; //larger
}

.x-large-text-light {
  font-family: $font-family-base;
  font-weight: 300;
  font-size: 125%; //x-large
}

.x-large-text-medium {
  font-family: $font-family-base;
  font-style: normal;
  font-weight: 400;
  font-size: 125%; //x-large
}

.x-large-text {
  font-family: $font-family-base;
  font-weight: 500;
  font-size: 125%; //x-large
}

.x-large-text-bold {
  @extend .x-large-text;
  font-weight: 700;
}

.xx-large-text-light-1 {
  font-family: $font-family-base;
  font-weight: 400;
  font-size: 135%; //xx-large
}

.xx-large-text-bold-1 {
  font-family: $font-family-base;
  font-style: normal;
  font-weight: 500;
  font-size: 137.5%;
}

.xx-large-text-light {
  font-family: $font-family-base;
  font-weight: 300;
  font-size: 140%; //xx-large
}

.xx-large-text {
  font-family: $font-family-base;
  font-weight: 800;
  font-size: 140%; //xx-large
}

.xxx-large-text-500 {
  font-family: $font-family-base;
  font-weight: 500;
  font-size: 160%; //xxx-large
}

.xxx-large-text {
  font-family: $font-family-base;
  font-weight: 600;
  font-size: 160%; //xxx-large
}

.ex-xxx-large-text {
  @extend .xxx-large-text;
  font-size: 175%; //xxx-large
}

.extra-large-bold-text {
  font-weight: bolder;
  font-size: 143%;
}

.ex-xxx-large-text-1 {
  @extend .normal-font-family-style;
  font-weight: 600;
  font-size: 175%;
}

.xxxx-large-text {
  font-family: $font-family-base;
  font-weight: 400;
  font-size: 250%; //xxx-large
}

.normal-font-family-style {
  font-family: $font-family-base;
  font-style: normal;
}

.larger-text-bold {
  @extend .normal-font-family-style;
  font-weight: 500;
  font-size: 112.5%;
}

.larger-text-400 {
  @extend .normal-font-family-style;
  font-weight: 400;
  font-size: 112.5%;
}

.larger-text-2 {
  font-family: $font-family-base;
  font-style: normal;
  font-weight: normal;
  font-size: 112.5%;
}

.ex-larger-text-500 {
  font-family: $font-family-base;
  font-style: normal;
  font-weight: 500;
  font-size: 162.5%;
  line-height: 40px;
}

.ex-larger-text-bold {
  font-family: $font-family-base;
  font-style: normal;
  font-weight: 600;
  font-size: 162.5%;
}

.ex-larger-text-light {
  font-family: $font-family-base;
  font-style: normal;
  font-weight: 400;
  font-size: 162.5%;
}

// New font classes that use rem unit instead of percentages

.small-text-bold-6 {
  font-size: $font_size_12;
  font-weight: 600;
}

.medium-text-normal {
  font-family: $font-family-base;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
}

.large-text-normal {
  font-size: $font_size_20;
  font-weight: normal;
}

.display {
  max-width: $max-width-1480;
  margin: auto;
  padding: 0.4em 0;
  min-height: 100vh;
}

.icon-size {
  width: 1.8em;
  height: 1.8em;
}

.icon-size-ex-cl {
  width: 1.4em;
  height: 1.4em;
}

.grey-bg {
  color: $forest;
  background: $grey;
}

.white-bg {
  color: $forest;
  background: $white;
}

.container-layout {
  max-width: $max-width-1480;
  min-width: $min-width-1280;
  margin: auto;
  padding: 0;
  min-height: 100vh;
}

.page-content {
  padding: 0;
}

.dropdown-item-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown-item-1 {
  color: $forest;
  @extend .ex-large-text;
  padding: 0 !important;
}

.dropdown-item-2 {
  color: $forest;
  margin: 0.5rem 0 0.938rem 0;
}

.sDropdown.p-dropdown {
  width: 92%;
  padding: 0.47em;
  background-color: $white;
  @extend .ex-large-text;
  color: $forest;
  box-shadow: none;
  border: 1px solid $forest_40;

  &:hover, 
  &:active,
  &:focus {
    border-color: $forest_40;
  }
}

.p-dropdown-trigger[aria-expanded="true"] {
  .pi-chevron-down:before {
    content: "\e903";
  }
}

.p-dropdown-trigger-icon {
  font-weight: bold;
  color: $forest;
}

.sDropdown-1 {
  @extend .sDropdown;
  background-color: $white;
}

.p-dropdown-panel {
  width: inherit;
  @extend .ex-large-text;
  background: $white;
  color: $forest;
  border: 1px solid $forest_20;
  border-radius: $sborder-radius;
  padding: 0;
  margin-top: 0.5rem;
  box-shadow: 0px 0.3124rem 0.3124rem 0px $forest_10 !important;

  .p-dropdown-item {
    background: $white;
  }
}

.fund-dropdown-panel {
  width: 13.75rem;
}

.sDropdown-1 .p-dropdown-panel {
  background: $white;
  border: 1px solid $forest_20;
}

.p-dropdown {
  border-radius: $sborder-radius;
  cursor: pointer;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background: $forest_10;
}

.p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  background: $forest_10;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  position: relative;
  padding: 0.5rem 1rem 0.5rem 1rem;

  .dropdown-panel-item-horiz-line {
    display: none;
  }

  &:last-child .dropdown-panel-item-horiz-line {
    border-bottom: 0.063rem solid transparent;
  }

  &.p-highlight .dropdown-panel-item-horiz-line {
    border-bottom: 0.063rem solid transparent;
  }
}

.sDropdown-1 .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background: $forest_10;
}

.p-dropdown-label {
  color: $forest;
  font-family: $font-family-base;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:last-child {
  border-bottom: none;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:first-child {
  &:hover,
  &.p-highlight {
    border-top-left-radius: $sborder-radius;
    border-top-right-radius: $sborder-radius;
  }
}

.p-dropdown:not(.p-disabled).p-focus {
  border: 1px solid $forest_40;
  box-shadow: none;
}

.p-dropdown-panel .p-dropdown-items {
  padding: 0;
}

.p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.straight-line {
  margin-top: 0.8em;
  margin-bottom: 0;
  padding-bottom: 0;
  border-top: 1px solid $forest_20;
  border-bottom: none;
  border-left: none;
  border-right: none;
}

.info-straight-line {
  @extend .straight-line;
  width: 100%;
  border: none;
  border-top: 1px solid $forest_20;
  margin: auto;
  align-items: center;
}

.info-text {
  padding: 0.5em 0 0 2.3em;
}

.info-text-1 {
  text-align: center;
}

.info-text-2 {
  padding: 1em 0 0 1em;
}

.info-text-3 {
  padding: 0 1em;
}

//For tooltip
.long-text-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: $white;
  bottom: -0.375rem;
  left: 48%;
  margin-left: -0.25rem;
  border-width: 0.625rem 0.625rem 0;
}

.long-text-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: $white;
  top: 41%;
  left: -0.375rem;
  margin-top: -0.25rem;
  border-width: 0.625rem 0.625rem 0.625rem 0;
}

.long-text-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: $white;
  top: 41%;
  right: -0.375rem;
  margin-top: -0.25rem;
  border-width: 0.625rem 0 0.625rem 0.625rem;
}

.long-text-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: $white;
  top: -0.375rem;
  left: 48%;
  margin-left: -0.25rem;
  border-width: 0 0.625rem 0.625rem;
}

.long-text-tooltip .p-tooltip-text {
  background: $white;
  color: $forest;
  padding: 1.25rem;
  border-radius: 0.188rem;
  box-shadow: 0 5px 5px 0 $forest_10;
  @extend .normal-font-family-style;
  font-weight: 500;
  font-size: 87.5%;
  line-height: 1.375rem;
}

.clickable-comp-highlight:hover {
  opacity: 0.8;
}

$auth-card-width: 43.75rem;
$auth-card-height: 28.75rem;

.auth-card-container-pos {
  max-width: 700px;
}

.auth-card-title-txt-font {
  @extend .normal-font-family-style;
  font-weight: 500;
  font-size: 150%;
  line-height: 2.5rem;
  text-align: center;
  color: $forest;
}

.auth-card-title-comp {
  width: 20.688rem;
  height: 5.063rem;
  margin: 0 auto 0 auto;
}

.auth-card-content-txt-font {
  @extend .normal-font-family-style;
  font-weight: normal;
  font-size: 100%;
  line-height: 1.375rem;
}

.auth-card-footer-text-font {
  @extend .normal-font-family-style;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1.375rem;
  text-align: right;
  color: $forest;
}

.auth-card-content-footer-dim {
  width: 22.563rem;
  height: 1.375rem;
  display: inline-block;
}

.auth-inputtext-password-style {
  height: 3.75rem !important;

  .input-inputtext {
    width: 100%;
    height: 100%;
    @extend .normal-font-family-style;
    font-weight: normal;
    font-size: 100%;
    line-height: 1.188rem;
    color: $forest;
    background: $white;
    border: 0.063rem solid $forest_40;
    box-sizing: border-box;
    border-radius: 0.188rem;
  }

  .p-inputtext:enabled:focus {
    border-color: $forest_40;
    box-shadow: none;
  }

  .p-inputtext:enabled:hover {
    border-color: $forest_40;
  }
}

.common-text-align-right {
  text-align: right;
}

.nav-export {
  .export-btn {
    background: $forest;
    border: 0;
    height: 3.25rem;
    margin-left: 1.25rem;
    width: fit-content;
    padding: 1.2rem;
    color: $white;
  }

  .export-btn:enabled:hover {
    background: $forest;
  }

  .export-btn.focus,
  .export-btn:focus {
    outline: 0;
    box-shadow: none !important;
    background: $forest !important;
  }

  .nav-export-btn {
    background: $forest;
    mix-blend-mode: normal;
    border-radius: 0.313rem;
  }

  .nav-export-btn:hover {
    background-color: $forest_80 !important;
  }
}

%bordered-button-forest{
  background: $white;
  border: 1px solid $forest;
  padding: 0.5rem;

  .p-button-label {
    @extend .large-text-1;
    padding: 0.2rem 0.5rem;
    text-align: center;
    color: $forest;
  }

  &:hover {
    background: $white!important;//Adding !important to override theme.css
    border-color: $forest!important;//Adding !important to override theme.css
    opacity: 0.5;
  }

  &:focus {
    box-shadow: none;
    background: $white;
    border-color: $forest;
  }
}

.grey-bordered-whit-bg-btn {
  @extend %bordered-button-forest;
  background: $white;
  border-radius: 0.313rem;
  border: 1px solid $forest;
  padding: 0.5rem;
}

%opaque-button-forest{
  background: $forest;
  border-radius: 0.313rem;
  border: none !important;
  padding: 0;

  .p-button-label {
    padding: 0 1.2rem 0 1.2rem;
    font-weight: 500;
    font-size: 1rem;
    text-align: center;
    line-height: 0.813rem;
    color: $white;
  }

  &:hover {
    background: $forest_80 !important;
  }

  &:focus {
    box-shadow: none;
  }
}

.common-yellow-bg-btn {
  @extend %opaque-button-forest;
}

.common-blue-bg-btn {
  @extend %opaque-button-forest;
}

.common-blue-1-bg-btn {
  @extend %opaque-button-forest;
}

.form-dropdown-panel {
  .p-dropdown-item {
    height: 50px;
    line-height: 50px;
    padding-top: 0 !important;
    border-bottom: 1px solid $forest_40 !important;
  }
}

%blue-yes-button {
  font-family: $font-family-base;
  padding: 0.5rem;
  width: 3.313rem !important;
  height: 2.563rem;
  color: $white;
  background: $forest;
  font-size: 0.875rem;
  font-weight: 500;
  border: none;
  border-radius: 0.313rem;

  &.p-button:not(a):not(.p-disabled):hover,
  &.p-button:not(a):not(.p-disabled):focus {
    background: $forest;
    box-shadow: none;
  }

  &.p-button:not(a):not(.p-disabled):hover {
    opacity: 0.6;
  }
}

%orange-yes-button {
  background: $red;

  &.p-button:not(a):not(.p-disabled):hover,
  &.p-button:not(a):not(.p-disabled):focus {
    background: $red;
    box-shadow: none;
  }

  &.p-button:not(a):not(.p-disabled):hover {
    opacity: 0.6;
  }
}

%white-no-button {
  font-family: $font-family-base;
  padding: 0.5rem;
  height: 2.563rem;
  width: 3.313rem !important;
  color: $forest;
  font-size: 0.875rem;
  font-weight: 500;
  background: $white;
  border: 1px solid $forest;
  border-radius: 0.313rem;

  &.p-button:not(a):not(.p-disabled):hover,
  &.p-button:not(a):not(.p-disabled):focus {
    background: $white;
    box-shadow: none;
    color: $forest;
    border: 1px solid $forest;
  }

  &.p-button:not(a):not(.p-disabled):hover {
    opacity: 0.6;
  }
}

.common-blue-btn {
  @extend %opaque-button-forest;

  .p-button-label {
    padding: 0.9375rem 1.2rem;
  }
}

%tab-header-trans-blue {
  color: $forest !important;
  background: transparent !important;
  @extend .medium-text;
  font-weight: 500 !important;
  border: none !important;
  box-shadow: none !important;
  height: 4.563rem;

  .p-column-title {
    line-height: 1.24rem;
  }
}

.margin-bottom-56 {
  margin-bottom: 3.5rem;
}

.disable-section {
  pointer-events: none;
  opacity: 0.6;
  cursor: none;

  .p-checkbox .p-checkbox-box {
    opacity: 0.9 !important;
  }
}

.no-models-text {
  background: $white;
  padding: 3em;
  text-align: left;
  @extend .small-text;
}

.button-red {
  @extend %orange-yes-button;
  border: none;
}

.button-highlight:enabled:hover {
  opacity: 0.6;
}

.clickable-highlight:hover {
  opacity: 0.6;
}

.dialog-close-highlight {
  .p-dialog-header {
    .p-dialog-header-icon:enabled:hover {
      color: $forest;
      border-color: transparent;
      background: $forest_20;
    }
  }
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.p-0 {
  padding: 0;
}

.d-flex {
  display: flex;
}

.d-grid {
  display: grid;
}

.d-none {
  display: none;
}

.text-left {
  text-align: left;
}

:export {
  //colours
  deep_ocean: $deep_ocean;
  forest: $forest;
  forest_10: $forest_10;
  forest_15: $forest_15;
  forest_20: $forest_20;
  forest_40: $forest_40;
  forest_50: $forest_50;
  forest_80: $forest_80;
  forest_40: $forest_40;
  sand: $sand;
  grey: $grey;
  white: $white;
  pink: $pink;
  blue: $blue;
  foliage: $foliage;
  red: $red;
  red_10: $red_10;
  red_15: $red_15;
  red_20: $red_20;
  red_50: $red_50;
  red_80: $red_80;
  green: $green;
  green_50: $green_50;
  green_20: $green_20;
  green_light: $green_light;
  nature: $nature;
  nature_20: $nature_20;
  yellow: $yellow;
  yellow_50: $yellow_50;
  foliage_50: $foliage_50;
  pink_dark: $pink_dark;
  green_dark: $green_dark;
  brown: $brown;
  blue_dark: $blue_dark;
  grey_light: $grey_light;
  borderWidth: $borderWidth;


  //fonts
  fontFamilyBase: $font-family-base;

  //heights
  header_Height_56: $header-height-56;
  height_fit_content: $height_fit_content;

  //widths
  width_1080_px: $px_1080;
}
