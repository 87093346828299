.events-grid-before-load {
  width: 100%;
  padding: 10px;
  .flex {
    justify-content: space-between;
  }

  .custom-skeleton {
    margin-bottom: 30px;
    margin-top: 0 !important;
    &:last-child {
      margin-bottom: 0;
    }
  }
}