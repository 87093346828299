@use '../../../../../resources/css/functions' as *;

.dq-completeness {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &__top-legend {
    min-width: toRem(198px);
    width: max-content;
    height: toRem(20px);
    margin-bottom: toRem(10px);

    span {
      margin-right: toRem(8px);
    }
  }

  &__chart {
    width: 100%;
    height: toRem(17px);
  }

  &__btm-legends {
    margin: toRem(15px) 0 0 0;

    span {
      margin: 0 toRem(10px) 0 toRem(8px);
    }
  }
}
