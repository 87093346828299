@import "../../../../resources/css/variables";

  .data-quality-legend {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    gap: 1.5em;
    align-items: center;
    margin-right: 1em;

    div {
      height: 1em;
      border-radius: 0.2rem;
      width: 1em;
      margin-right: -0.8em;
    }

    p {
      font-family: $font-family-base;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.0625rem;
      color: $forest;
    }

    .actual_data_swatch {
      background-color: $forest;
    }

    .estimated_data_swatch {
      background-color: $forest_50;
    }

    .incomplete_data_swatch {
      background-color: $nature;
    }

    .not_covered_data_swatch {
      background-color: $sand;
    }
  }

.fund-report-table {
  .p-datatable {
    width: 100%;
    background-color: white;
  }

  .table-cell-wide {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: $forest_10;
    align-items: center;
  }

  .p-datatable-header {
    background-color: $forest_10;
    padding: 0;
    border: none;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .flex-row p {
    margin: 0em 0em 0em 0.62em;
    color: $forest;
  }

  .report-button-flex {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 1em;
    margin-right: 1.88em;
  }

  h4 {
    color: $forest;
    font-size: 0.875rem;
    font-weight: 700;
    margin-left: 1.88em;
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }
  
  .p-datatable .p-datatable-thead > tr > th, 
  .p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    padding: 0;
    border-bottom: 1px solid $forest_10;
    font-size: 0.875rem;
    font-weight: 400;
    color: $forest;
  }

  .p-datatable .p-datatable-thead > tr > th {
    font-weight: 700;
    background-color: white;
    padding: 1.25em 1em;
  }

  .p-datatable .p-datatable-tbody > tr > td {
    font-weight: 400;
    padding: 1em;

    &.highlighted:hover {
      background: $forest_05;
    }
  }

  .p-datatable .p-datatable-thead > tr > th:first-child, 
  .p-datatable .p-datatable-tbody > tr > td:first-child {
    padding-left: 1.88em;
    width: 30%;
  }

  .p-datatable .p-datatable-thead > tr > th:last-child, 
  .p-datatable .p-datatable-tbody > tr > td:last-child {
    width: 20%;
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    box-shadow: 0px 5px 5px 0px rgba(37, 80, 86, 0.10);
    border-radius: 0.3125rem;
    border: 1px solid $forest_20;
    overflow: hidden;
  }
}