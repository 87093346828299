.static-nzc-chart-graph-container {
    position: relative;
    height: 35rem;

    .p-chart {
      height: 35rem;
    }

    canvas {
      max-width: 100%;
    }
}