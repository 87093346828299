@use '../../../../resources/css/functions' as *;

.db-completeness-chart {
  &__percentage {
    display: flex;
    min-width: toRem(164px);
    max-width: max-content;
    height: toRem(17px);
    margin: 0 0 toRem(10px) 0;
  }

  &__period {
    min-width: toRem(192px);
    max-width: max-content;
    height: toRem(17px);
    margin: 0 0 toRem(20px) 0;
    line-height: 17px;

    .icon-calendar {
      margin: 0 toRem(6px) 0 0;
    }
  }

  &__chart {
    height: toRem(12px);
    margin: 0 0 toRem(20px) 0;
  }

  &__legends {
    & > div {
      min-width: toRem(174px);
      max-width: max-content;
      height: toRem(17px);
      margin: 0 0 toRem(10px) 0;

      .icon-square_solid {
        margin: 0 toRem(8px) 0 0;
      }
    }
  }
}

.db-completeness-chart-assets {
  &__title {
   display: flex;
   align-items: center;
   gap: toRem(10px);
  }

  &__chart {
    height: toRem(12px);
    margin-top: toRem(20px);
    margin-bottom: toRem(10px);
  }

  &__legends {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    & > div {
      margin: 0 0 toRem(10px) 0;
      .icon-square_solid {
        margin: 0 toRem(8px) 0 0;
      }
    }
  }
}