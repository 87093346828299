.terms-and-conditions {
  .bold {
    font-weight: bold;
  }
  .li-alpha {
    list-style-type: lower-alpha !important;
  }

  .li-decimal {
    list-style-type: none !important;
    position: relative;
    margin-left: 0;
  }

  .ol-decimal {
    counter-reset: item; // Reset counter for main list
    list-style: none;
    padding-left: 0;
  }

  .ol-decimal > .li-decimal {
    counter-increment: item; // Increment for main items
  }

  .ol-decimal > .li-decimal::before {
    content: counter(item) ". "; // Main numbering
    left: 0;
  }

  .ol-decimal ol {
    counter-reset: sub-item; // Reset sub-item counter for nested lists
    list-style: none;
    padding-left: 1rem;
  }

  .ol-decimal ol > .li-decimal {
    counter-increment: sub-item; // Increment for sub-items
    position: relative;
    padding-left: 2rem; // Padding for custom numbering
  }

  .ol-decimal ol > .li-decimal::before {
    content: counter(item) "." counter(sub-item) ". "; // Nested numbering (e.g., 2.1, 2.2)
    position: absolute;
    left: 0;
  }
}