@use '../../../../../resources/css/functions' as *;

.qq-menu-header {
  height: toRem(52px);
  margin: 0 0 toRem(43px) 0;

  &__menus {
    .siera-dropdown--light {
      width: toRem(262px);
      margin: 0 toRem(20px) 0 0;
    }
  }

  &__search-meter {
    .search-field {
      width: toRem(262px);
      .search-input-text {
        width: toRem(262px);
      }
    }
  }
}