@import '../../../resources/css/variables';
@import '../../../resources/css/common';

.bar-month-tooltip {
  @extend .medium-text-normal;

  opacity: 1;
  background: $white;
  border: 1px solid $forest_20;
  border-radius: 3px;
  color: $forest;
  position: absolute;
  pointer-events: none;
  transition: all .1s ease;
  min-width: 17.56rem;
  max-width: fit-content;
  min-height: 9.125rem;
  padding: 0.5rem 1.25rem 1rem 1.25rem;
  box-shadow: 0 5px 5px 0 $forest_10;
  z-index:2;

  .tooltip-arrow {
    top: 2rem;
  }

  &.left-arrow-triangle {
    .tooltip-arrow {
      position: absolute;
      background: $white;
      width: 1rem;
      height: 1rem;
      transform: rotate(45deg);
      left: -0.55rem;
      border-left: 1px solid $forest_20;
      border-bottom: 1px solid $forest_20;
    }
  }

  .bar-month-tooltip-content {

    .tooltip-header {
      img {
        margin-right: 0.5rem;
        width: 1rem;
        height: 1rem;
      }

      .header-scope-section {
        height: 1rem;
        line-height: 1rem;
        margin-bottom: 1rem;

        .header-scope-value {
          float: right;
        }
      }

      .header-target-type-section {
        height: 1rem;
         display: flex;
         align-items: center;

        span:first-letter {
          text-transform: uppercase;
        }
      }
    }

    .data-container {
      display: flex;
      margin: 1rem 0 0 0;
      line-height: 1.5rem;
      justify-content: space-between;

      .target-container {

        .target-wrapper {
          display: flex;
          justify-content: space-between;
        }
      }

      .section-divider {
        width: 2px;
        background-color: transparent;
        opacity: 0.3;
        margin: 0 1rem 0 1rem;
      }

      .value-container {
        min-width: 35%;
        display: flex;
        flex-direction: column;
        text-align: right;
      }
    }

    .tooltip-footer {
      display: flex;
      align-items: center;
      margin-top: 1rem;

      img {
        width: 1rem;
        margin-right: 0.5rem;
      }
    }
  }
}