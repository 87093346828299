@use '../../../../../resources/css/functions' as *;

.dq-coverage {
  &__legends {
    margin-left: toRem(20px);

    &__d-coverage {
      min-width: toRem(164px);
      width: max-content;
      height: toRem(20px);
      margin-bottom: toRem(10px);

      span {
        margin-right: toRem(8px);
      }
    }

    &__a-coverage {
      min-width: toRem(192px);
      width: max-content;
      height: toRem(17px);
      margin-bottom: toRem(10px);

      .siera-text {
        text-transform: lowercase;
      }

      .icon {
        margin-right: toRem(8px);
      }
    }

    &__a-unaccounted {
      min-width: toRem(227px);
      width: max-content;
      height: toRem(17px);

      .siera-text {
        text-transform: lowercase;
      }

      .icon {
        margin-right: toRem(8px);
      }
    }
  }
}

