.completedActions-grid-before-load {
  padding: 40px;

  .custom-skeleton {
    margin-bottom: 30px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.action-grid-skeleton {
  justify-content: space-between;

  & > div {
    flex-basis: 1;
  }
}