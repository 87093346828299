@use '../../../../../resources/css/functions' as *;

.months-legend {
  overflow: visible;
  width: 100%;
  display: grid;

  &__col-top {
    height: 0.5rem;
  }

  &__col-month {
    min-height: toRem(30px);
    text-align: center;

    &.xs {
      line-height: toRem(30px);
    }
  }

  &__col-bottom {
    height: 0.5rem;
  }

  &__col-year {
    height: toRem(20px);
  }

  .qtr-top {
    border-top: 1px solid rgba(37, 80, 86, 0.2);
    border-left: 1px solid rgba(37, 80, 86, 0.2);
  }

  .qtr-border-top-r {
    border-right: 1px solid rgba(37, 80, 86, 0.2);
  }

  .qtr-border {
    border-left: 1px solid rgba(37, 80, 86, 0.2);
  }
}